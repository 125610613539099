import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import ScanningStepComponent from '../components/connection-modal/scanning-step.jsx';
import VM from 'scratch-vm';
import {flashMegatonFirmware} from './firmware-update-func/megaton-firmware-update';
import {setUploadingStatus, setUploadStatus, setOpenStatusModal} from '../reducers/mode'
import { closeConnectionModal } from '../reducers/modals.js';


import {connect} from 'react-redux';

class ScanningStep extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'updateMegatonFirmware',
        ]);
        this.state = {
            scanning: true,
            peripheralList: []
        };
    }
    componentDidMount () {
        this.props.vm.scanForPeripheral(this.props.extensionId);
    }
    componentWillUnmount () {
    }
    updateMegatonFirmware () {
        this.props.onStartUploadFirmware()
        flashMegatonFirmware('https://test-client-stem.s3.amazonaws.com/megaton-firmware/sb-arduino-fw.ino.with_bootloader.hex', (error, result) => {
            if (error) {
                // Handle error
                console.error(error);
                this.props.onUploadFail();
            } else {
                // Handle success
                console.log(result);
                this.props.onUploadSuccess();
            }
            this.props.onStopUploadFirmware();
            this.props.onOpenStatusModal();
            this.props.disableConnectionalModal();
        });
    }
    render () {
        return (
            <ScanningStepComponent
                connectionSmallIconURL={this.props.connectionSmallIconURL}
                peripheralList={this.state.peripheralList}
                phase={this.state.phase}
                scanning={this.state.scanning}
                title={this.props.extensionId}
                onConnected={this.props.onConnected}
                onConnecting={this.props.onConnecting}
                isUploading={this.props.isUploading}
                onUpdatePeripheral={this.updateMegatonFirmware}
            />
        );
    }
}

ScanningStep.propTypes = {
    connectionSmallIconURL: PropTypes.string,
    extensionId: PropTypes.string.isRequired,
    onConnected: PropTypes.func.isRequired,
    onConnecting: PropTypes.func.isRequired,
    onStartUploadFirmware: PropTypes.func.isRequired,
    onStopUploadFirmware: PropTypes.func.isRequired,
    onUpdatePeripheral: PropTypes.func,
    isUploading: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    // isUpdateSuccess: state
})

const mapDispatchToProps = dispatch => ({
    onStartUploadFirmware: () => dispatch(setUploadingStatus(true)),
    onStopUploadFirmware: () => dispatch(setUploadingStatus(false)),
    onOpenStatusModal: () => dispatch(setOpenStatusModal(true)),
    disableConnectionalModal:() => dispatch(closeConnectionModal()),
    onUploadSuccess: () => dispatch(setUploadStatus(true)),
    onUploadFail: () => dispatch(setUploadStatus(false))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScanningStep);
